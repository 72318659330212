import React, { createRef, useRef } from "react";
import io from "socket.io-client";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//mport Row from 'react-bootstrap/Row';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VideoCall from "../helpers/simple-peer";
import { getDisplayStream } from "../helpers/media-access";
import { CamOffIcon, CamOnIcon, FullScreenIcon, MicOffIcon, MicOnIcon} from "../Components/Icons";
//, ShareScreenIcon, EndCallButton 
import Loading from "react-fullscreen-loading"; 
import RecordRTC from 'recordrtc';
import html2canvas from "html2canvas";
import {v4 as uuidv4} from 'uuid'; 

var MediaStreamRecorder = require('msr');

const socketConf  =  process.env.REACT_APP_SOCKET_URL;
var localRecordRef = null;
var remoteRecordRef = null;
var combinedRecordRef = null;
export default class Room extends React.Component{
  constructor() {
    super();
    this.state = {
      recMode:"",
      
      mediaRecorder:null,
      localStream: {},
      remoteStream:{},
      remoteStreamUrl: '',
      streamUrl: '',
      initiator: false,
      peer: {},
      full: false,
      connecting: false,
      waiting: true,
      micState:true,
      camState:true,
      userType:null,
      roomName_:null,
      //new fix
      disableVidBtn :false,
      disableMicBtn:false,
      //videorecording states
      recordVideo: null,
      src: null,
      uploadSuccess: null,
      uploading: false

    };
    this.getUserMedia = this.getUserMedia.bind(this);
    this.setAudioLocal = this.setAudioLocal.bind(this);
    this.setVideoLocal = this.setVideoLocal.bind(this);
    this.getDisplay = this.getDisplay.bind(this);
    this.enter = this.enter.bind(this);
    this.call = this.call.bind(this);
    this.renderFull = this.renderFull.bind(this);
   
    this.ToggleFullScreen = this.ToggleFullScreen.bind(this);
    this.LeaveRoom = this.LeaveRoom.bind(this);
    this.setConstraints = this.setConstraints.bind(this);
    this.mediaConfig= null;
    this.iceServers = this.setIceServers(this);
    this.startRecording = this.startRecording.bind(this);
    this.stopRecording = this.stopRecording.bind(this);
    
   
    this.chunks = [];
  } 
  videoCall = new VideoCall();

  startRecording(e){
    const recFileName = `${uuidv4()}`
    document.getElementById('btn-start-record').disabled = true;
    var arrayOfStreams = [this.state.localStream, this.state.remoteStream ];

    combinedRecordRef = new MediaStreamRecorder.MultiStreamRecorder(arrayOfStreams);
    
    //combinedRecordRef.start(); 
    localRecordRef = new RecordRTC( this.state.localStream, {
        //mimeType:'video',
        // recorderType: MediaStreamRecorder,
      mimeType: 'video/webm',
      timeSlice: 6000 // pass this parameter
    });
    localRecordRef.startRecording();

    this.setState({recMode:"start"});
    
/*
    var internal = localRecordRef.getInternalRecorder();
setInterval(()=>{
    if(internal && internal.getArrayOfBlobs) {
      var blob = new Blob(internal.getArrayOfBlobs(), {
          type: 'video/webm'
      });
     console.log('Recording length: ' + blob.size);
    }
  },6000);*/




  }


  stopRecording()
  {
    this.setState({recMode:"stop"});
    
    document.getElementById('btn-start-record').disabled = false;
    document.getElementById('btn-stop-record').disabled = true;
    localRecordRef.stopRecording();
    if(localRecordRef.getBlob() != null){
     console.log(localRecordRef.getBlob());

   //  document.getElementById('preview-video').src = URL.createObjectURL(localRecordRef.getBlob());
    }


  }
 

  setConstraints(){
    let _configs = localStorage.getItem('configuration');
    if(_configs===null)
      window.location.href = "../../";
    _configs = JSON.parse(_configs);
    //_configs.EnableVideo
    //_configs.VideoDeviceId
    let deviceId = false;
    if(_configs.EnableVideo)
      if(_configs.VideoDeviceId != null && _configs.VideoDeviceId !== "")
        deviceId = JSON.parse("{\"exact\": \""+_configs.VideoDeviceId+"\"}");

    this.mediaConfig = {
       video: _configs.EnableVideo?{
        deviceId,
        width: { min: 160, ideal: 640, max: 1920  },
        height: { min: 120, ideal: 400, max: 1080  },
      }:true,
      audio: true
    };
    if(_configs.EnableVideo!==null){
      this.setState({camState:_configs.EnableVideo});
      if(_configs.EnableVideo===false)
        this.setState({disableVidBtn:true});
    }
    if(_configs.EnableAudio!==null){
      this.setState({micState:_configs.EnableAudio});
      if(_configs.EnableAudio===false)
        this.setState({disableMicBtn:true});
    }
  }

  setIceServers = ()=>{
    let stun_list =  process.env.REACT_APP_STUN_LIST;

    let iceServers = [];
    stun_list = stun_list.split('|');
     stun_list.map(det=>{
      if(det.includes('~')){
        let crendentials = det.split('~');
          iceServers.push({"urls":crendentials[0], "username":crendentials[1], "credential":crendentials[2]});
      }else
       iceServers.push({"urls":det});
    });
    //alert(JSON.stringify(iceServers))
    return (iceServers);
  }

  componentDidUpdate(){
    if(this.state.localRecordRef!=null && this.state.recMode =="stop"){
       // console.log(this.state.localRecordRef.getBlob())
       this.state.localRecordRef.stopRecording();
      console.log(this.state.localRecordRef)
    }

  }
  componentDidMount() {
    



    let res = localStorage.getItem('hostResponse');
    res = JSON.parse(res);
   
   
    if(this.mediaConfig===null)
      this.setConstraints();
    if(this.mediaConfig){ 
          let room = res.member.sessionId.replaceAll('-','');
          this.setState({userType:res.member.role.name})
          document.body.classList.add("room-bg-color");
          document.body.classList.add("established");
          document.body.classList.add("media-bridge");

          const socket = io(socketConf);
          const component = this;
          this.setState({ socket });
          const roomId  = room;
          this.setState({roomName_:roomId});
          this.getUserMedia().then(() => {
            const payload = { roomId: roomId }
            socket.emit('join', payload);
          });

          socket.on('init', () => {
            component.setState({ initiator: true });
          });
          socket.on('ready', () => {
            toast.dismiss();
            toast("Joining call");
            component.enter(roomId);
          });
          socket.on('desc', data => {
            if (data.type === 'offer' && component.state.initiator) return;
            if (data.type === 'answer' && !component.state.initiator) return;
            component.call(data);
          });
          socket.on('disconnected', () => {
           // toast("Connection lost waiting for reconnection");
            // component.setState({ initiator: true });
          });
          //
          socket.on('endcall',payload=>{
            toast.info(`${(this.state.userType==="HOST"?"participant":"host")} disconnected the meeting.`);
           setTimeout(()=>{
            window.location.reload();
           },5500);
            
            
          // window.location.href="../../thanks";
        

           
          });
          socket.on('full', () => {
            component.setState({ full: true });
          });
      }else
        alert("null");
  }

  getUserMedia(cb) {
    return new Promise((resolve, reject) => {
      navigator.getUserMedia = navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;
        if (typeof navigator.mediaDevices.getUserMedia === 'undefined'){
            navigator.getUserMedia(
              this.mediaConfig,
              stream => {
                this.setState({ streamUrl: stream, localStream: stream });
                this.localVideo.srcObject = stream;
                resolve();
              },
              () => {}
            );
        }else{
          navigator.mediaDevices.getUserMedia(
              this.mediaConfig).then(
              stream => {
                this.setState({ streamUrl: stream, localStream: stream });
                this.localVideo.srcObject = stream;
                
                if(this.state.camState!=null && this.state.camState == false)
                  if(stream.getVideoTracks().length>0){
                    stream.getVideoTracks().forEach(track => {
                      track.enabled=!track.enabled;
                    });
                }
                if(this.state.micState!=null && this.state.micState == false)
                {
                  if(stream.getAudioTracks().length>0){
                    stream.getAudioTracks().forEach(track => {
                      track.enabled=false;
                    });
                  }
                }
                resolve();
          }).catch(() => {});
        }
    });
  }
  setAudioLocal(){
    if(this.state.localStream.getAudioTracks().length>0){
      this.state.localStream.getAudioTracks().forEach(track => {
        track.enabled=!track.enabled;
      });
    }
    this.setState({
      micState:!this.state.micState
    });

  


  }

  setVideoLocal(){
    if(this.state.localStream.getVideoTracks().length>0){
      this.state.localStream.getVideoTracks().forEach(track => {
        track.enabled=!track.enabled;
      });
    }
    this.setState({
      camState:!this.state.camState
    })
  }

  getDisplay = async()=> {
   await getDisplayStream().then(stream => {
      stream.oninactive = () => {
        this.state.peer.removeStream(this.state.localStream);
        this.getUserMedia().then(() => {
          this.state.peer.addStream(this.state.localStream);
        });
      };
      this.setState({ streamUrl: stream, localStream: stream });
      this.localVideo.srcObject = stream;
      
     
      this.state.peer.addStream(stream);
      
    });
  }
  LeaveRoom = () =>{
    this.setState({connecting:true});
    const signal = {
      room: this.state.roomName_,
    };
    this.state.socket.emit('endcall', signal);
    this.state.socket.emit('leave', this.state.roomId);//remove this if he after joiining in the same session
   // alert(this.state.roomName_);
    window.location.href="../../thanks";
   // this.state.socket.emit('leave', this.state.roomId);
  }
  enter = roomId => {
   
    this.setState({ connecting: true });
    const peer = this.videoCall.init(
      this.state.localStream,
      this.state.initiator,
      this.iceServers
    );
    this.setState({ peer });
    peer.onconnectionstatechange = (ev) => {
      //console.log(peer.connectionState)
    };

    peer.on('signal', data => {
      const signal = {
        room: roomId,
        desc: data
      };
      this.state.socket.emit('signal', signal);
    });
    peer.on('stream', stream => {
      if(typeof this.remoteVideo !== "undefined" && this.remoteVideo ){ 
      //  if(this.remoteVideo.current !== null){
       // toast(`${this.state.userType==="HOST"?"Participant":"Host"} joined...`);
       this.setState({ remoteStream: stream });
        if ('srcObject' in this.remoteVideo){ //changes
         
          this.remoteVideo.srcObject = stream;
        }else{//changes
          this.remoteVideo.src =  window.URL.createObjectURL(stream);//changes
        }
        this.remoteVideo.play();//changes
        this.setState({ connecting: false, waiting: false }); 
      // }else
      }else{
        console.log("Remote strm undefined");
      }
    });
    peer.on('error', function(err) {
      console.log(err);
    });
  };

  call = otherId => {
    this.videoCall.connect(otherId);
  };
  renderFull = () => {
    if (this.state.full) {
      return 'The room is full';
    }
  };

  ToggleFullScreen =  ()=>{
    let elemtn = this.remoteVideo;
    if (elemtn.requestFullscreen) {
      elemtn.requestFullscreen();
    } else if (elemtn.webkitRequestFullscreen) { /* Safari */
    elemtn.webkitRequestFullscreen();
    } else if (elemtn.msRequestFullscreen) { /* IE11 */
    elemtn.msRequestFullscreen();
    }

  }


render(){
    return (<> <Navbar bg="dark" data-bs-theme="dark">
    <Container> 
      <Nav className="me-auto"> 
      <div className="media-controls ">
      {this.state.connecting && ("Loading")}{/*<button
          className={`control-btn audio-button-${this.state.micState}`}
          onClick={() => {
            this.getDisplay();
          }}
        >
        <ShareScreenIcon/>
        </button>*/}
        <button
        className='control-btn audio-button-true'
          onClick={() => {
            this.setAudioLocal();
          }} disabled={this.state.disableMicBtn}
        >
          {this.state.micState?(
            <MicOnIcon/>
            ):(
              <MicOffIcon/>
            )
          }
        </button>
        <button
        className='control-btn video-button-true'
          onClick={() => {
            this.setVideoLocal();
          }}  disabled={this.state.disableVidBtn}
        >
          {
            this.state.camState?(
             <CamOnIcon/>
            ):(
              <CamOffIcon/>
            )
          }
        </button> 
        <button onClick={() => {
            this.ToggleFullScreen();
          }}  className="control-btn fullscreen-button">
          <FullScreenIcon/>
        </button>

        <button className="dc-btn" onClick={() => {
            this.LeaveRoom();
          }} >
          End 
        </button>
        <button
          className={`control-btn ms-2 d-none audio-button-`}
          id="btn-start-record"
          onClick={() => {
            this.startRecording();
          }}
        >
          STAT 
        </button>

        <button
          className={`control-btn ms-2 d-none audio-button-`}
          id="btn-stop-record"
          onClick={() => {
            this.stopRecording();
          }}
        >
          STOP 
        </button>
    </div>
      </Nav>
    </Container>     
  </Navbar>
        <>  <div className="d-">
          {/*this.state.connecting?true:false*/}
        <Loading loading={false} background="#e6e2e2" loaderColor="#ff738b"/>
        {this.state.waiting && (
          <div className='status'>  
            <center className="text-white">Please wait for the {this.state.userType&&(this.state.userType==="HOST"?"participant":"host")}</center>
          </div>
        )}
        </div>
        <div id="call-window">
        <div
            style={{ backgroundColor: "#535253"}}
            className="">
            <div className="">
                <video  id="remote-video" ref={remVid=>(this.remoteVideo=remVid)} className="remote-video remoteVideo" autoPlay playsInline/>
            </div>
            </div>
            <div className="">{this.state.connecting && (
         <div className='status'>
           {/*<center className="text-white">Establishing connection...</center>*/}
          </div> 
        )} 
       
       <ToastContainer pauseOnHover={false}/>
        {/*<ToastContainer /> ${this.state.connecting || this.state.waiting ?   'hide': ''}*/}
                <video muted id="local-video" ref={refdet=>(this.localVideo=refdet)} className={`localVideo local-video `} autoPlay playsInline />
            </div>
            </div>
            </> 
        </>
    );
  }
}