
const VideoSelect = ({options, setVideoInput, videoInput}) =>{

  return (
    <>
      {(options && options?.length>=1)?<select
        className="form-control" onChange={(e)=>{e.preventDefault();setVideoInput(e.target.value);}}
      >
        {options.map((videoDevice, index) => {
                                return (videoInput === videoDevice.deviceId)?<option key={`vidoption${index}`} selected value={videoDevice.deviceId}> {videoDevice.label === ""?`Video input ${index+1}`:videoDevice.label}</option>:<option key={`vidoption${index}`} value={videoDevice.deviceId}> {videoDevice.label === ""?`Video input ${index+1}`:videoDevice.label}</option>;
                            })}
                            
      </select>:""}
    </>
  );

}
export default VideoSelect;