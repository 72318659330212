import { toast } from 'react-toastify';

const InitMedia = async (Vid_deviceId, constraints,userVideo, setStream, videoEnable, setError)=>{ 

    document.body.classList.add("loading");
    if(constraints===null || constraints === ""){
      document.body.classList.remove("loading");
        return;
    }
    if(constraints!==null || constraints !== ""){
        if(typeof constraints.video.deviceId === "undefined"){
            document.body.classList.add("loading");
            
            return;
        };
     
        await navigator.mediaDevices
        .getUserMedia({video:{
            deviceId: {
            exact: Vid_deviceId,
        },max: {width:800,height:600},
        min: {width:640,height:480},
        frameRate: { ideal: 10, max: 30 }},audio:true}).then((stream)=>{
            if(stream != null && typeof stream != "undefined")
            { 
                if(!videoEnable){
                // if(typeof stream.getVideoTracks()[0] != "undefined"){
                        stream.getVideoTracks()[0].stop();
                        //stream.getVideoTracks().forEach(track => track.stop());
                // }
                }
                userVideo.current.srcObject = stream;
              //  userVideo.onloadedmetadata = () => {
               //     userVideo.play();
               //   };
                setStream(stream);
              //  setLoaded(true);
            }
        }).catch((error) => {
            if(error.name.includes("NotReadableError")){  
                setError("NotReadableError");
            }document.body.classList.remove("loading");
            return;
        });
    }
    document.body.classList.remove("loading");
}
export default InitMedia;