
import logo from "../assets/hummy.png";

const Home = () =>{
    document.body.classList.add("bg-grey");
    return <><div className="content" >
    <div className="">
      <div className="text-center mt-5" style={{backgroundColor:"#d3d3d3!important"}}>
        <div className="col-md-12 pt-5 col-xs-12 col-sm-12">
            <div className="logo">
              <img src={logo} alt="Hummy" className="lo_go img-fluid"/>
            </div>
          <div className="col-md-4 hidden-xs hidden-sm"></div>
          <div className="col-md-4 col-xs-4 col-sm-4 text-right" style={{width:"30%",float:"right"}}>
          </div>
        </div>
        <div className="col-md-12 col-xs-12 col-sm-12">
          <div className="patient_alert" style={{backgroundColor:"#d3d3d3 !important"}}>
            <p className="text-small">Communication APIs for Audio, Video, Chat and more...</p>
            <p className="h6">For more details contact us at <b>info@genixtec.com</b></p>
          </div>
        </div>
      </div>
    </div>
  </div></>;
};

export default Home;