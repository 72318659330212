
import React from "react";
import { BrowserRouter, Route, Routes} from "react-router-dom";
import CreateRoom from "./routes/CreateRoom";
import Room from "./routes/Room.js";
import NotFound from "./PageSections/NotFound.js";
import ThankYou from "./PageSections/ThankYou.js";
import Home from "./PageSections/Home.js";

function App() {

	return (
		<BrowserRouter>
		  <Routes>
			<Route path="/:sessionKey" exact element={<CreateRoom/>} />
			<Route path="/room/:roomID/:roleId" element={<Room/>} />
			<Route path="/*" element={<NotFound/>} />
			<Route path="/" element={<Home/>} />
			<Route path="/thanks" element={<ThankYou/>} />
		  </Routes>
		</BrowserRouter>
	  );
	}

export default App