const ListDevices = async (setPermissionError)=>{
  navigator.getMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    const devicess = await navigator.mediaDevices.enumerateDevices();
    const queries = devicess
      .filter(({ kind }) => kind === "videoinput")
      .map(({ deviceId }) => ({ name: "camera", deviceId }));

  // Firefox 1.0+
  let isFirefox = typeof InstallTrigger !== 'undefined';
  
      if(isFirefox){

        try{
        queries.map((queryObj) =>{
         
             //navigator.permissions.query({ name: queryObj.camera });
        });
        const devices = await navigator.mediaDevices?.enumerateDevices?.();
          if (devices) {
              const video = [];
              const audio = [];
              for (const device of devices) {
                  switch (device.kind) {
                      case 'videoinput':{
                        if(device.label!==""){
                          video.push(device);
                          break;
                        }
                      }
                      case 'audioinput':
                        if(device.label!==""){
                          audio.push(device);
                          break;
                        }
                      default:
                  }
              }
                if(video.length === 0 || audio.length === 0){
                  setPermissionError(true);
                }
                  
              return { video, audio };
          } else {
              throw new Error('No support for multimedia devices.');
          }
        }catch (error) {
          alert(error);
        }


      }else{
          const promises = queries.map((queryObj) =>
            navigator.permissions.query(queryObj)
          );
          try {
            const results = await Promise.all(promises);
            results.forEach(({ state }, i) =>{ 
              if(state==='denied')
              {
                setPermissionError(true);
                    return;
              }
            });
          } catch (error) {
            console.error(error);
          }
        }
    //microphone
    const devices = await navigator.mediaDevices?.enumerateDevices?.();
    if (devices) {
        const video = [];
        const audio = [];
        for (const device of devices) {
            switch (device.kind) {
                case 'videoinput':{
                  if(device.label!==""){
                    video.push(device);
                    break;
                  }
                }
                case 'audioinput':
                  if(device.label!==""){
                    audio.push(device);
                    break;
                  }
                default:
            }
        }
        return { video, audio };
    } else {
        throw new Error('No support for multimedia devices.');
    }
};

export default ListDevices;
