import Peer from 'simple-peer'

//const stun_list =  process.env.REACT_APP_STUN_LIST;
/*//
//google
'stun:stun.l.google.com:19302|stun:stun2.l.google.com:19302|stun:stun3.l.google.com:19302|stun:stun4.l.google.com:19302|stun:iphone-stun.strato-iphone.de:3478'
//metered
stun:stun.relay.metered.ca:80~17f1651501ffdd68e31509f7~8T6K4YI6p0eT4FeS|turn:standard.relay.metered.ca:80?transport=tcp~17f1651501ffdd68e31509f7~8T6K4YI6p0eT4FeS|turn:standard.relay.metered.ca:443~17f1651501ffdd68e31509f7~8T6K4YI6p0eT4FeS|turns:standard.relay.metered.ca:443?transport=tcp~17f1651501ffdd68e31509f7~8T6K4YI6p0eT4FeS
//genix POC
stun:stun.genixits.com~guest~somepassword|turn:turn.genixits.com~guest~somepassword
// 'stun:stun.l.google.com:19302|stun:stun2.l.google.com:19302|stun:stun3.l.google.com:19302|stun:stun4.l.google.com:19302|stun:iphone-stun.strato-iphone.de:3478'
*/

export default class VideoCall {
   
    peer = null 
    init = (stream, initiator, iceServerJson) => {
    
        this.peer = new Peer({
            initiator:initiator,// initiator, test 5-3-2024
            stream: stream,
            trickle: false,
            reconnectTimer: 6000,
            iceTransportPolicy: 'relay',
            config: {
                iceServers:iceServerJson
            }
        })
        return this.peer
    }
    connect = (otherId) => {
        this.peer.signal(otherId)
    }  
} 