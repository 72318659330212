const Footer = () =>{
    return <footer className="footer bg-grey">
    <div className="col-sm-12 mt-1 text-center">
    <div>
        <span className="text-muted ">
        <span >
        Powered by
        <a href="https://hummy.io/" target="_blank" rel="noreferrer"><img  src="https://hummy.io/img/hummy.png" alt="Hummy " className="lo_go img-fluid" style={{width:"75px"}}/></a></span>
        </span>
    </div>
        </div>
    </footer>;
};
export default Footer;