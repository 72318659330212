const AudioSelect = ({options, setAudioInput, audioInput}) =>{
  const updateValue = ({ target }) => {
    if (setAudioInput) setAudioInput(target.value);
  };

    return (
      <>
        {(options && options?.length>=1)?<select
          className="form-control form-select" onChange={updateValue}
        >
          {options.map((device, index) => {
                                  return (audioInput === device.deviceId)?<option key={`vidoption${index}`} selected value={device.deviceId}>{device.label === ""?`Mic input ${index+1}`:device.label}</option>:<option key={`vidoption${index}`} value={device.deviceId}>{device.label === ""?`Mic input ${index+1}`:device.label}</option>;
                              })}
                              
        </select>:""}
      </>
    );
}
export default AudioSelect;
