import React, { useState, useEffect, useRef } from "react";
import { VideoIcon, MicIcon } from "../assets";
import {ListDevices, VideoSelect,AudioSelect,VideoPlayer,InitMedia, ToggleButton,EmptySelect, CamOffContainer,NotReadableContainer } from "../Components/Index";
import axios, { HttpStatusCode } from 'axios';
import { useParams } from "react-router-dom";
import Footer from "../PageSections/Footer";
import { ToastContainer, toast } from 'react-toastify';
import Loading from "react-fullscreen-loading"; 


const CreateRoom = () => {
    const [roomname,setRoomName ] = useState("myroom");//, setRoomName
    const [permissionError, setPermissionError] = useState(null);
    const [loading, setLoading] = useState(true);

    const [devices, setDevices] = useState(null);
    const [error, setError] = useState(null);
    const [errorType, setErrorType] = useState(null);
    const [videoInput, setVideoInput] = useState(null);
    const [audioInput, setAudioInput] = useState(null);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [micEnabled, setMicEnabled] = useState(true);
    const [constraints, setConstraints] = useState(null);
    const userVideo = useRef();
    const [vidstream, setStream] = useState(null);
    //const [apiResponse, setApiResponse] = useState(null);
    //const code ="";// new URLSearchParams(window.location.search).get("code");
    const [roledetail, setRoleDetails] = useState(null);
    const {sessionKey} = useParams();

    useEffect(() => {
        document.body.classList.remove("bg-grey");
        let sessionResponse = null;
        if(sessionKey==="" || sessionKey === null){
            toast("Invalid session");return;
        }
        (async ()=>{
        await setMedias();
        await axios.get(`${process.env.REACT_APP_HUMMY_URL}Session/GetMemberDetails?code=${sessionKey}`)
        .then( res=>{
            if(res.status===HttpStatusCode.Ok){
                if(res.data.status[0].code !== "999"){ 
                    if(res.data.member.statusId !== 1){
                        toast("Session already completed"); return ;
                    }
                    document.body.classList.add("bg-grey");
                    setRoleDetails(res.data.member.role.name.toLowerCase());
                    localStorage.setItem('hostResponse',JSON.stringify(res.data));
                    sessionResponse = res.data;
                     ListDevices(setPermissionError)
                    .then((devices) =>{ 
                        if(devices.video.length !== 0  && devices.audio.length !== 0){
                           /* testing*/
                          
                            setDevices(devices);
                            setVideoInput(devices?.video[0].deviceId);
                            setRoomName(res.data.member.sessionId.replaceAll('-',''));
                            //setAudioInput(devices?.audio[0].deviceId);
                            if(devices && devices.length>=1)
                                InitMedia (videoInput, constraints, userVideo, setStream, videoEnabled,setError);
                        }else{
                            document.body.classList.remove("loading");
                      //  toast("No video capturing devices found, could proceed with this device, Try again with another device");
                        return;
                    }
                    }).catch((error) =>{alert(error) ;setError(error)});

                }else{
                    toast(res.data.status[0].message);return;
                }
            }else{
                toast("Invalid session");return;
            }
        });

    })().then(res=>{
        if(devices===null){
            document.body.classList.remove("loading");
           // setError("No valid media capturing devices found");
            return;
        } 
        
    });
    }, []);

    useEffect(() => { 
        if(devices && devices.length>=1)
            InitMedia (videoInput, constraints, userVideo, setStream, videoEnabled, setError);
    },[videoEnabled, micEnabled]);

    useEffect(() => {
       if(vidstream != null && typeof vidstream.getVideoTracks()[0] != "undefined"){
            vidstream.getTracks().forEach(t =>{ t.stop();
                if(vidstream)
                    vidstream.removeTrack(t);
            });
        }
        setConstraints({
            video:{
                deviceId:  videoInput != null ?{
                exact: videoInput,
                }:undefined
            }
        });
    },[videoInput]);

    useEffect(() => {
        InitMedia (videoInput, constraints, userVideo, setStream, videoEnabled,setError);
    },[constraints]);
    useEffect(() => {
      setLoading(false);
       if(error !== "NotReadableError"){
        toast(error);
       }else{
            setErrorType("NotReadableError");
       }
    },[error]);

    const setMedias = async ()=>{
        //Initial permission setting
        navigator.getUserMedia = navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;
        if (typeof navigator.mediaDevices.getUserMedia === 'undefined'){
            await navigator.getUserMedia({video:true,audio:true},
              async stream => {
                if(stream != null && typeof stream != "undefined")
                { 
                    await navigator.mediaDevices.enumerateDevices();   
                    userVideo.current.srcObject = stream;
                    setStream(stream);
                }
              },
              () => {}
            );
        }else{
         await navigator.mediaDevices.getUserMedia({video:true,audio:true}).then(
             async  stream => {
                if(stream != null && typeof stream != "undefined")
                { 
                   await  navigator.mediaDevices.enumerateDevices(); 
                    userVideo.current.srcObject = stream;
                    setStream(stream);
                }
          }).catch(() => {});
        }
        //EOF Initial permission setting
        //<ToastContainer /> 
    }

    function create() {
        if(roomname!==""){ 
           const configs = {
             EnableVideo : videoEnabled,
             VideoDeviceId:videoInput,
             EnableAudio:micEnabled ,
             AudioDeviceId:audioInput,
             roomId:roomname
           };
           localStorage.setItem("configuration",JSON.stringify(configs));
           window.location.href= (`/room/${roomname}/${roledetail}`);
        }
    }
    
    return (<>
        <div className="mt-5 h-100 "><ToastContainer/>
            <div className=" d-flex align-items-center justify-content-center">
            {permissionError?"No valid media capturing devices found, Refresh this page and allow required permission to continue...":""}
                <div className="">
                <div className="card border-radius-0 shadow-sm">
                {devices !==null? <>
                    <div className="card-header">
                        <h4 className="text-danger">Meeting options</h4> 
                    </div>
                    <div className="card-body">
                        {error && (<div>{error}</div>)}
                        <div className="col-sm-12 row card-page" >
                            <div className="col-sm-6 col-xs-12 float-start left-sec-welcome" >
                        <div className="mb-3 pt-3" title="Video input source">
                            {devices.video.length === 0 && (<div>Video devices: not detected!</div>)}
                                {devices.video.length > 0 && (<>
                                <ToggleButton icon={VideoIcon} enabled={videoEnabled} onChangeCallback={setVideoEnabled} />
                            <p>
                            <label className="text-label form-label align-middle pt-2 pe-2">Camera Options 
                            </label>
                                {videoEnabled?<VideoSelect options={devices.video} setVideoInput={(e)=>{setVideoInput(e); }}  videoInput={videoInput} />:<EmptySelect options={devices.video} selectedValue={videoInput} />}</p>
                                </>)}
                        </div>
                        <div className="mb-2 pt-4"  title="Audio input source">
                        {devices.audio.length === 0 && (<div>Audio devices: not detected!</div>)}
                        {devices.audio.length > 0 && (<>
                            <ToggleButton icon={MicIcon} enabled={micEnabled} onChangeCallback={setMicEnabled} />
                           <p>
                           <label className="text-label form-label align-middle pt-2 pe-2">Microphone Options</label>
                        {micEnabled?<AudioSelect options={devices.audio} setAudioInput={setAudioInput} audioInput={audioInput}/> :<EmptySelect options={devices.audio} selectedValue={audioInput} />}</p>
                    </>)}
                        </div>
                    </div>
                        <div className="col-sm-6 col-xs-12 float-start text-center right-sec-welcome" >
                            {errorType === "NotReadableError" && videoEnabled?<NotReadableContainer />:""}
                            <CamOffContainer videoEnabled={videoEnabled}/>
                            {errorType !== "NotReadableError" || !videoEnabled?<VideoPlayer videoReference={userVideo} enabled={videoEnabled} />:""}
                        </div>
                    </div>
                    </div></>:<Loading  loading={loading} background="rgb(207 209 220)" loaderColor="rgb(114 83 116)"/>}
                
                </div>
            </div>
            </div>  
           

            {devices && (<><div className="col-sm-12 mt-4">
                <div className="form-group text-center">
                    <button disabled={roledetail===null || error!==null} className="btn btn-lg btn-danger shadow" type="submit" onClick={create}>
                        Continue
                    </button>
                </div>
            </div></>)}
        </div>
        <Footer/>
    </>
    );
};

export default CreateRoom;