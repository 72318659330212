
//import logo from "../assets/hummy.png";
import Footer from "./Footer";

const ThankYou = () =>{
  localStorage.removeItem('hostResponse');
  localStorage.removeItem('configuration');

    document.body.classList.add("bg-grey");
    return <><div className="content" >
    <div className=" d-flex align-items-center justify-content-center">
      <div className="col-sm-8 mt-5">
      
        <div className="col-md-12 pt-5 col-xs-12 col-sm-12" >
          <div className="patient_alert mt-5" style={{backgroundColor:"#d3d3d3 !important"}}>
            <p className=" mt-3">Thank you for joining.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
  </>;
};

//background #d3d3d3!important
export default ThankYou;