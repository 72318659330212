
import NTFOUNDImg from "../assets/404.png";

const NotFound = () =>{
    document.body.classList.add("bg-grey");
    return <><div className="container">
        <div className="align-items-center justify-content-center" style={{textAlign:"center",padding:"100px 0px"}}>
        <div className="">
        <div className="col-md-12 col-xs-12 col-sm-12 pat_top">
        <div className="col-md-12 col-xs-12 col-sm-12">
        <div className="404page">
                <img src={NTFOUNDImg} alt="Page not found"  className="img-fluid"/> 
            </div>
            <div  className="wrong-content"><p  className="wrong-pages">OOPS, THE PAGE YOU ARE LOOKING FOR CAN’T BE FOUND </p></div>

            </div>
            </div>
    </div></div></div></>;
};

//background #d3d3d3!important
export default NotFound;