import Switch from "react-switch";
const ToggleButton = ({icon,enabled, onChangeCallback }) =>{

    return <> <img alt="videoIcon" src={icon} className="pe-2" />
    <Switch checked={enabled} uncheckedIcon={<div className="ps-2 text-white" style={{paddingTop:"2px"}}>OFF</div>}  
height={32}  onColor="#dc3545"  offColor="#939090" 
width={73} handleDiameter={20}  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" 
checkedIcon={<div className="ps-2 text-white" style={{paddingTop:"2px"}}>ON</div>} onChange={(e)=>{onChangeCallback(e);}}/> </>
}
export default ToggleButton;