import NOCAM from "../assets/no_cam.png";
const NotReadableContainer = () =>{
    return <div  className="camOff center ">
    <div className="jumbotron vertical-center">
        
<div className="container ">
<img src={NOCAM} className="img-fluid"/>
    <h6>No available camera input</h6>
<small className="fw-normal">Device is unavailable or used by another application</small>
</div>
</div></div>;
}
export default NotReadableContainer;